import React, { useEffect } from 'react'
import { WithStore } from 'pure-react-carousel'

interface SlideChangeProviderProps {
  onSlideChanged: (slide: number) => void
}

const SlideChangeProviderBase: React.FC<
  SlideChangeProviderProps & { currentSlide: number }
> = ({ currentSlide, onSlideChanged }) => {
  useEffect(() => {
    onSlideChanged(currentSlide)
  }, [currentSlide]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export const SlideChangeProvider = (WithStore(
  SlideChangeProviderBase as any,
  state => ({ currentSlide: state.currentSlide })
) as unknown) as React.ComponentType<SlideChangeProviderProps>
