import React from 'react'
import Container, { ContainerProps } from '@material-ui/core/Container'
import { useTheme, useMediaQuery, Paper } from '@material-ui/core'

export interface AutoPaperProps {
  maxWidth?: ContainerProps['maxWidth']
}

export const ResponsivePaper: React.FC<AutoPaperProps> = ({
  maxWidth = 'xs',
  children
}) => {
  const theme = useTheme()
  const wide = useMediaQuery(theme.breakpoints.up('sm'))

  return wide ? (
    <Container maxWidth={maxWidth}>
      <Paper>{children}</Paper>
    </Container>
  ) : (
    <>{children}</>
  )
}
