// TODO: https://github.com/CSFrequency/react-firebase-hooks/pull/41

import { useMemo } from 'react'
import { useDocument, DocumentDataHook } from 'react-firebase-hooks/firestore'
import { firestore } from 'firebase/app'

const snapshotToData = (
  snapshot: firestore.DocumentSnapshot,
  idField?: string
) => {
  if (!snapshot.exists) {
    return undefined
  }

  return {
    ...snapshot.data(),
    ...(idField ? { [idField]: snapshot.id } : null)
  }
}

const useDocumentData = <T>(
  docRef?: firestore.DocumentReference | null,
  options?: {
    idField?: string
    snapshotListenOptions?: firestore.SnapshotListenOptions
  }
): DocumentDataHook<T> => {
  const idField = options ? options.idField : undefined
  const snapshotListenOptions = options
    ? options.snapshotListenOptions
    : undefined
  const [snapshot, loading, error] = useDocument(docRef, {
    snapshotListenOptions
  })
  const value = useMemo(
    () => (snapshot ? snapshotToData(snapshot, idField) : undefined) as T,
    [snapshot, idField]
  )
  return [value, loading, error]
}

export default useDocumentData
