import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
// Required for side-effects
import 'firebase/functions'

firebase.initializeApp({
  apiKey: 'AIzaSyAagrP53sRmtqd6lrrQnLTpCvwYZV1W8WI',
  authDomain: 'card.pep-rg.jp',
  databaseURL: 'https://clearly-38b8a.firebaseio.com',
  projectId: 'clearly-38b8a',
  storageBucket: 'clearly-38b8a.appspot.com',
  messagingSenderId: '766775973866'
})
