import React from 'react'
import { Typography, Box } from '@material-ui/core'

export const CopyrightNotice: React.FC = () => (
  <Box padding={2}>
    <Typography color="textSecondary">
      Reuse of the content is not permitted.
    </Typography>
  </Box>
)
