import React, { useState, useEffect } from 'react'
import { getDefinition } from '../functionsClient'

interface DefinitionLoaderProps {
  active: boolean
  word: string
  courseId: string
  children: (text: string | null) => React.ReactElement // null for error
}

const promiseMap: Record<string, Promise<string | null>> = {}

function loadDefinition(word: string, courseId: string) {
  let promise = promiseMap[word]
  if (promise) return promise

  promise = promiseMap[word] = (async () => {
    const cacheKey = `clearly-definition-${courseId}-${word}`
    const cachedDef = sessionStorage.getItem(cacheKey)
    if (cachedDef !== null) return cachedDef || null

    const def =
      (await getDefinition({ word, courseId })).data.definition ||
      '（定義情報なし）'
    sessionStorage.setItem(cacheKey, def)
    return def || null
  })()

  return promise
}

export const DefinitionLoader: React.FC<DefinitionLoaderProps> = ({
  active,
  word,
  courseId,
  children
}) => {
  const [def, setDef] = useState<string | null | undefined>(undefined)

  useEffect(() => {
    if (!active) return

    let cancel = false
    loadDefinition(word, courseId)
      .catch(e => {
        console.error(e)
        return null
      })
      .then(def => {
        if (!cancel) setDef(def)
      })

    return () => {
      cancel = true
    }
  }, [active, word, courseId])

  return def !== undefined ? children(def) : null
}
