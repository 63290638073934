import firebase from 'firebase/app'

export interface TypedHttpsCallableResult<T> {
  readonly data: T
}

export interface TypedHttpsCallable<Req, Resp> {
  (data: Req): Promise<TypedHttpsCallableResult<Resp>>
}

export const getDefinition = firebase
  .functions()
  .httpsCallable('definition') as TypedHttpsCallable<
  { word: string; courseId: string },
  { definition: string | null }
>
