/// <reference types="gapi.client.sheets" />

import React, { useState, useMemo, useEffect } from 'react'
import Picker from './Picker'
import SpreadSheetLoader from './SpreadSheetLoader'
import {
  Button,
  MenuItem,
  ListItemText,
  CircularProgress,
  Snackbar,
  TextField,
  makeStyles,
  createStyles
} from '@material-ui/core'
import firebase from 'firebase/app'
import { CourseEntity } from '../entities'
import FlashCardList from '../flashcard/FlashCardList'
import { FlashCardPreferencesContainer } from '../flashcard/FlashCardPreferencesContainer'
import { useCourseCollection } from '../hooks'

async function loadSpreadSheet(docId: string, accessToken: string) {
  await new Promise(resolve => gapi.load('client', () => resolve()))
  await gapi.client.load('sheets', 'v4')
  // TODO: Error handling
  const res = await gapi.client.sheets.spreadsheets.get({
    access_token: accessToken,
    spreadsheetId: docId,
    includeGridData: true
  })
  return new SpreadSheetLoader(res.result.sheets!)
}

async function runImport(course: CourseEntity, destinationId: string | null) {
  const collection = firebase.firestore().collection('courses')
  const docRef =
    destinationId === null ? collection.doc() : collection.doc(destinationId)
  await docRef.set(course)
}

const Preview: React.FC<{ course: CourseEntity }> = ({ course }) => (
  <div style={{ margin: 32, height: '60vh' }}>
    <FlashCardPreferencesContainer.Provider
      initialState={{ courseId: null, course }}
    >
      <FlashCardList cards={course.cards} />
    </FlashCardPreferencesContainer.Provider>
  </div>
)

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(3, 0)
      }
    }
  })
)

const Importer: React.FC<{ user: firebase.User; accessToken: string }> = ({
  user,
  accessToken
}) => {
  const [pickerOpen, setPickerOpen] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [importState, setImportState] = useState<
    'idle' | 'running' | 'success' | 'error'
  >('idle')
  const [loader, setLoader] = useState<SpreadSheetLoader | null>(null)
  const [sheetTitle, setSheetTitle] = useState('')
  const [destinationId, setDestinationId] = useState('new')
  const classes = useStyles()

  const { courses } = useCourseCollection()

  const parseResult = useMemo(
    () => (loader && sheetTitle ? loader.parseSingleSheet(sheetTitle) : null),
    [loader, sheetTitle]
  )

  useEffect(() => {
    if (['success', 'error'].includes(importState)) {
      setSnackbarOpen(true)
    }
  }, [importState])

  const sheetLoader = (parseResult && parseResult.sheetLoader) || null
  const parseError = (parseResult && parseResult.error) || null
  const course: CourseEntity | null = sheetLoader
    ? {
        name: sheetLoader.sheetTitle,
        cards: sheetLoader.cards,
        mediaTypes: sheetLoader.mediaTypes
      }
    : null

  return (
    <div className={classes.root}>
      <div>
        <Button
          variant="contained"
          onClick={() => setPickerOpen(true)}
          disabled={loading || importState === 'running'}
        >
          Pick file to import
        </Button>
        {loading && <CircularProgress />}
      </div>
      <div>
        <Picker
          open={pickerOpen}
          user={user}
          accessToken={accessToken}
          onPick={docId => {
            setPickerOpen(false)
            setLoading(true)
            loadSpreadSheet(docId, accessToken)
              .then(loadedLoader => {
                setLoading(false)
                setLoader(loadedLoader)
                setSheetTitle(loadedLoader.sheetTitles[0] || '')
              })
              .catch(() => setLoading(false))
          }}
          onCancel={() => setPickerOpen(false)}
        />
      </div>
      {loader &&
        (loader.sheetTitles.length > 0 ? (
          <div>
            <TextField
              select
              label="Sheet"
              value={sheetTitle}
              onChange={e => setSheetTitle(e.target.value as string)}
              disabled={importState === 'running'}
            >
              {loader.sheetTitles.map(title => (
                <MenuItem key={title} value={title}>
                  <ListItemText>{title}</ListItemText>
                </MenuItem>
              ))}
            </TextField>
          </div>
        ) : (
          <div>No sheet for import</div>
        ))}
      {parseError && <div>{parseError}</div>}
      {sheetLoader && course && (
        <>
          {sheetLoader.isValid ? (
            <>
              <div>
                <TextField
                  style={{ width: 300 }}
                  select
                  label="Destination"
                  value={destinationId}
                  onChange={e => setDestinationId(e.target.value)}
                  disabled={importState === 'running'}
                >
                  <MenuItem key="new" value="new">
                    （Create new course）
                  </MenuItem>
                  {courses.map(course => (
                    <MenuItem key={course.id} value={course.id}>
                      {course.name} ({course.id})
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setImportState('running')
                    runImport(
                      course,
                      destinationId === 'new' ? null : destinationId
                    )
                      .then(() => setImportState('success'))
                      .catch(e => {
                        console.error(e)
                        setImportState('error')
                      })
                  }}
                  disabled={importState === 'running'}
                >
                  Import
                </Button>
                {importState === 'running' && <CircularProgress />}
              </div>
              <h4>Preview</h4>
              <Preview course={course} />
            </>
          ) : (
            <div>
              <div>Error Found:</div>
              {sheetLoader.errors.map((error, i) => (
                <div key={i}>
                  {error.detail} / row: {error.row} / columns:{' '}
                  {error.columns && error.columns.join(', ')}
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        message={
          importState === 'success'
            ? 'Importing completed.'
            : 'Something wrong was happened while importing.'
        }
        onClose={() => {
          setSnackbarOpen(false)
        }}
        autoHideDuration={5000}
      />
    </div>
  )
}

export default Importer
