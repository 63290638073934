import React from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import useRouter from 'use-react-router'
import { useCourseCollection } from '../hooks'

interface Props {
  onClickItem?: () => void
}

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      borderBottom: '1px solid rgba(0, 0, 0, 0.16)'
    },
    courses: {
      flexGrow: 1,
      overflowY: 'scroll'
    },
    loadingContainer: {
      padding: theme.spacing(2),
      textAlign: 'center'
    }
  })
)

const DrawerContentBody: React.FC<
  Props & {
    loading: boolean
    courses: {
      id: string
      name: string
    }[]
  }
> = ({ loading, courses, onClickItem }) => {
  const classes = useStyles()
  const { location, history } = useRouter()
  return (
    <>
      <header className={classes.header}>
        <Typography
          variant="h6"
          onClick={() => {
            if (onClickItem) onClickItem()
            history.push('/')
          }}
        >
          Courses
        </Typography>
      </header>
      <nav className={classes.courses}>
        <List>
          {loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          )}
          {courses.map(course => {
            const path = `/courses/${course.id}`
            return (
              <ListItem
                key={course.id}
                button
                selected={location.pathname.startsWith(path)}
                component={({ className, children }) => (
                  <Link className={className} to={path} onClick={onClickItem}>
                    {children}
                  </Link>
                )}
              >
                <ListItemText>{course.name}</ListItemText>
              </ListItem>
            )
          })}
        </List>
      </nav>
    </>
  )
}

export const DrawerContent: React.FC<Props> = ({ onClickItem }) => {
  const { loading, courses } = useCourseCollection()
  return (
    <DrawerContentBody
      loading={loading}
      courses={courses}
      onClickItem={onClickItem}
    />
  )
}
