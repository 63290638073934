import { useCollection } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app'

export function useCourseCollection() {
  const [value, loading, error] = useCollection(
    firebase
      .firestore()
      .collection('courses')
      .orderBy('name')
  )
  const courses = value
    ? value.docs.map(doc => ({
        id: doc.id,
        name: doc.get('name') as string
      }))
    : []

  return { loading, courses, error }
}
