import React, { useState, useRef, RefObject } from 'react'
import { CardEntity } from '../entities'
import {
  CarouselProvider,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel'
import ReactResizeDetector from 'react-resize-detector'
import { FlashCard } from './FlashCard'
import { SlideChangeProvider } from './SlideChangeProvider'
import {
  LinearProgress,
  Box,
  Portal,
  Slide as MuiSlide,
  makeStyles,
  createStyles,
  Icon
} from '@material-ui/core'

interface FlashCardListProps {
  cards: CardEntity[]
}

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      transitionTimingFunction: 'ease-out',
      transitionDuration: '.1s',

      // disable long tap
      userSelect: 'none'
    },
    button: {
      position: 'absolute',
      top: 0,
      bottom: 64, // toolbar 48 + margin 8 + divider etc 8
      width: '20%',
      minWidth: 64,

      cursor: 'pointer',
      background: theme.palette.action.hover,
      border: 'none',
      outline: 'none',
      padding: 0,
      appearance: 'none',
      opacity: 0,

      color: theme.palette.common.white,

      '&:active': {
        opacity: 1
      },

      '&$leftButton': {
        left: 0
      },
      '&$rightButton': {
        right: 0
      }
    },

    leftButton: {},
    rightButton: {}
  })
)

const BodyBase: React.FC<
  FlashCardListProps & { progressRef: RefObject<HTMLElement> }
> = ({ cards, progressRef }) => {
  // TODO: Too heavy setCurrentSlide. Consider better way to propagate 'active' value to children.
  const [currentSlide, setCurrentSlide] = useState(0)
  const classes = useStyles()
  return (
    <>
      <Slider classNameAnimation={classes.slider}>
        <Portal container={progressRef.current}>
          <LinearProgress
            variant="determinate"
            value={(currentSlide / (cards.length - 1)) * 100}
          />
        </Portal>
        <SlideChangeProvider onSlideChanged={slide => setCurrentSlide(slide)} />
        {cards.map((card, i) => (
          <Slide key={card.word} index={i}>
            <FlashCard card={card} active={i === currentSlide} />
          </Slide>
        ))}
      </Slider>
      <ButtonBack className={`${classes.button} ${classes.leftButton}`}>
        <Icon>arrow_back</Icon>
      </ButtonBack>
      <ButtonNext className={`${classes.button} ${classes.rightButton}`}>
        <Icon>arrow_forward</Icon>
      </ButtonNext>
    </>
  )
}

// Many cards, it's heavy.
const Body = React.memo(BodyBase)

const FlashCardList: React.FC<FlashCardListProps> = ({ cards }) => {
  const progressRef = useRef<HTMLDivElement>(null)
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      position="relative"
    >
      <Box flexGrow={1} overflow="hidden" marginBottom={1}>
        <ReactResizeDetector
          handleWidth
          handleHeight
          render={({ width, height }) =>
            width && height ? (
              <MuiSlide in direction="left">
                <CarouselProvider
                  naturalSlideWidth={width}
                  naturalSlideHeight={height}
                  totalSlides={cards.length}
                >
                  <Body cards={cards} progressRef={progressRef} />
                </CarouselProvider>
              </MuiSlide>
            ) : (
              <div />
            )
          }
        />
      </Box>
      <div ref={progressRef} />
    </Box>
  )
}

export default FlashCardList
