/// <reference types="google.picker" />
/// <reference types="../types/google-picker-ambient" />

import React, { useEffect, useRef, useState } from 'react'
import { GOOGLE_API_KEY } from '../api-keys'

export interface PickerProps {
  open: boolean
  user: firebase.User
  accessToken: string
  onPick: (docId: string) => void
  onCancel?: Function
}

const PickerBody: React.FC<
  Pick<PickerProps, 'accessToken' | 'onPick' | 'onCancel'>
> = ({ accessToken, onPick, onCancel }) => {
  const callbacksRef = useRef({ onPick, onCancel })
  useEffect(() => {
    callbacksRef.current = { onPick, onCancel }
  }, [onPick, onCancel])
  useEffect(() => {
    const view = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
    view.setMimeTypes('application/vnd.google-apps.spreadsheet')
    view.setEnableTeamDrives(true)
    view.setIncludeFolders(true)
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
      .addView(view)
      .setOAuthToken(accessToken)
      .setDeveloperKey(GOOGLE_API_KEY)
      .setCallback((obj: any) => {
        const action = obj[google.picker.Response.ACTION]
        if (action === google.picker.Action.PICKED) {
          const docs = obj[google.picker.Response.DOCUMENTS]
          const docId = docs[0][google.picker.Document.ID]
          callbacksRef.current.onPick(docId)
        } else if (action === google.picker.Action.CANCEL) {
          if (callbacksRef.current.onCancel) callbacksRef.current.onCancel()
        }
      })
      .build()
    picker.setVisible(true)
    return () => picker.dispose()
  }, [])
  return null
}

const PickerContainer: React.FC<PickerProps> = ({
  open,
  user,
  accessToken: token,
  onPick,
  onCancel
}) => {
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    if (open) {
      gapi.load('picker', () => {
        user.getIdToken(true).then(() => setIsReady(true))
      })
    } else {
      setIsReady(false)
    }
  }, [open, user])
  return isReady ? (
    <PickerBody accessToken={token} onPick={onPick} onCancel={onCancel} />
  ) : null
}

const Picker: React.FC<PickerProps> = props => {
  return <PickerContainer {...props} />
}

export default Picker
