/// <reference types="gapi.client.sheets" />

import { CardEntity, cardMediaTypes, CourseEntity } from '../entities'
import { without, fromPairs } from 'lodash-es'

type Header = Exclude<keyof CardEntity, 'image'>
const HEADERS = without(cardMediaTypes, 'image') as Header[]
const REQUIRED_HEADERS: Header[] = ['word', 'translation']

export class SheetLoader {
  sheetTitle: string
  errors: { detail: string; row?: number; columns?: string[] }[] = []
  cards: CardEntity[] = []
  mediaTypes: CourseEntity['mediaTypes'] = {}

  private data: gapi.client.sheets.GridData
  private rows: Partial<CardEntity>[] = []
  private validated = false

  constructor({
    sheetTitle,
    data
  }: {
    sheetTitle: string
    data: gapi.client.sheets.GridData
  }) {
    this.sheetTitle = sheetTitle
    this.data = data
  }

  get isValid() {
    return this.validated && this.errors.length === 0
  }

  private calcMediaTypes(): CourseEntity['mediaTypes'] {
    return fromPairs(
      HEADERS.map(type => {
        const rowCount = this.rows.filter(row => !!row[type]).length
        if (rowCount === 0) return null
        return [
          // TODO: I have no idea why readonly array is not accepted by fromParis.
          ...([
            type,
            rowCount === this.rows.length ? 'complete' : 'partial'
          ] as const)
        ]
      }).filter((entry): entry is NonNullable<typeof entry> => !!entry)
    )
  }

  private get headers(): string[] {
    return this.data.rowData![0].values!.map(
      v => (v.effectiveValue || {}).stringValue || ''
    )
  }

  parse() {
    this.validated = true
    this.errors = []
    this.validateHeader()
    if (!this.isValid) return null
    this.parseRows()
    this.validateRows()
    if (!this.isValid) return null
    this.cards = this.rows as CardEntity[]
    this.mediaTypes = this.calcMediaTypes()
    return this.cards
  }

  private validateHeader() {
    const headerSet = new Set(this.headers)
    const missingHeaders = REQUIRED_HEADERS.filter(
      header => !headerSet.has(header)
    )
    if (missingHeaders.length > 0) {
      this.errors.push({
        row: 1,
        detail: 'missing_header',
        columns: missingHeaders
      })
    }
  }

  private validateRows() {
    this.rows.forEach((row, i) => {
      const blankColumns = REQUIRED_HEADERS.filter(header => !row[header])
      if (blankColumns.length > 0) {
        this.errors.push({
          row: i + 2, // start from 1, skip header row
          detail: 'missing_required_value',
          columns: blankColumns
        })
      }
    })
  }

  private parseRows() {
    const mappings = HEADERS.map<[Header, number]>(type => [
      type,
      this.headers.indexOf(type)
    ]).filter(([_, index]) => index >= 0)

    this.rows = this.data
      .rowData!.slice(1)
      .filter(
        rowData =>
          rowData.values && rowData.values.some(col => this.stringValue(col))
      )
      .map(rowData => {
        const row: Partial<CardEntity> = {}
        for (const [type, index] of mappings) {
          const value = this.columnAt(rowData, index)
          const trimmedValue = value ? value.trim() : null
          if (trimmedValue) {
            row[type] = trimmedValue
          }
        }
        return row
      })
  }

  columnAt(
    rowData: gapi.client.sheets.RowData,
    columnIndex: number
  ): string | null {
    const value = rowData.values![columnIndex]
    return this.stringValue(value)
  }

  private stringValue(
    value: gapi.client.sheets.CellData | null
  ): string | null {
    return ((value && value.effectiveValue) || {}).stringValue || null
  }
}

export default class SpreadSheetLoader {
  private sheets: gapi.client.sheets.Sheet[]

  constructor(sheets: gapi.client.sheets.Sheet[]) {
    this.sheets = sheets
  }

  get sheetTitles() {
    return this.sheets
      .filter(sheet => sheet.data)
      .map(sheet => sheet.properties!.title!)
  }

  parseSingleSheet(
    sheetTitle: string
  ): { sheetLoader?: SheetLoader; error?: string } {
    const sheet = this.sheets.find(
      sheet => sheet.properties!.title === sheetTitle
    )
    if (!sheet) {
      return { error: `Sheet with title ${sheetTitle} does not exist.` }
    }

    if (!sheet.data) {
      return { error: `Sheet with title ${sheetTitle} does not contain data.` }
    }

    const sheetLoader = new SheetLoader({
      sheetTitle: sheet.properties!.title!,
      data: sheet.data[0]
    })
    sheetLoader.parse()
    return { sheetLoader }
  }
}
