import React from 'react'
import { Drawer as MuiDrawer, withStyles } from '@material-ui/core'
import { DrawerContent } from './DrawerContent'
import { DrawerContainer } from './DrawerContainer'
import { CopyrightNotice } from '../misc/CopyrightNotice'

export const Drawer: React.FC = () => {
  const { drawerOpen, setDrawerOpen } = DrawerContainer.useContainer()
  return (
    <StyledDrawer
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      keepMounted
    >
      <DrawerContent onClickItem={() => setDrawerOpen(false)} />
      <CopyrightNotice />
    </StyledDrawer>
  )
}

const StyledDrawer = withStyles({
  paper: {
    width: '100%',
    maxWidth: 280 // from material-components-web
  }
})(MuiDrawer)
