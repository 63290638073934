export interface CourseEntity {
  name: string
  cards: CardEntity[]
  mediaTypes: Partial<Record<CardMediaType, 'complete' | 'partial'>>
}

export interface CardEntity {
  word: string
  translation: string
  definition?: string
  sentence?: string
  image?: string
}

export type CardMediaType =
  | 'word'
  | 'translation'
  | 'definition'
  | 'sentence'
  | 'image'

export const cardMediaTypes = Object.freeze<CardMediaType>([
  'word',
  'translation',
  'definition',
  'sentence',
  'image'
])

export type MediaType = CardMediaType | 'audio'

export const mediaTypes = Object.freeze<MediaType>([...cardMediaTypes, 'audio'])

export type Side = 'front' | 'back'

export const sides = Object.freeze<Side>(['front', 'back'])
