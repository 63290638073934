import React from 'react'
import { IconButton, Icon, Box } from '@material-ui/core'
import { DrawerContainer } from './DrawerContainer'

export const DrawerButton: React.FC = () => {
  const { drawerOpen, setDrawerOpen } = DrawerContainer.useContainer()
  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Courses"
        onClick={() => setDrawerOpen(!drawerOpen)}
      >
        <Icon>menu</Icon>
      </IconButton>
      <Box marginRight={2} />
    </>
  )
}
