import { createContainer } from 'unstated-next'
import { useState, useCallback, useEffect } from 'react'
import {
  MediaType,
  Side,
  CardMediaType,
  cardMediaTypes,
  CourseEntity
} from '../entities'
import { omit, countBy, invertBy, difference } from 'lodash-es'

type Config = Partial<Record<MediaType, Side>> & {
  availableMediaTypeMap: CourseEntity['mediaTypes']
  courseId: string | null
}

interface InitState {
  courseId: string | null
  course: CourseEntity
}

export const FlashCardPreferencesContainer = createContainer(
  (initialState: InitState | undefined) => {
    const [currentConfig, setConfig] = useState<Config>(
      initialState
        ? getInitialConfig(initialState)
        : { availableMediaTypeMap: {}, courseId: null }
    )

    const reInit = useCallback(
      (initState: InitState) => setConfig(getInitialConfig(initState)),
      []
    )

    const toggle = (type: MediaType, side: Side) => {
      setConfig(config => {
        const nextActive = config[type] !== side
        if (nextActive) {
          const newConfig = { ...config, [type]: side }

          const afterCounts = getCompleteMediaCounts(newConfig)
          const reducedSide = side === 'front' ? 'back' : 'front'
          if (afterCounts[reducedSide] > 0) return newConfig

          const firstAvailableType = cardMediaTypes.find(
            t => t !== type && config.availableMediaTypeMap[t] === 'complete'
          )!
          return { ...newConfig, [firstAvailableType]: reducedSide }
        } else {
          const newConfig = omit(config, type)
          const afterCounts = getCompleteMediaCounts(newConfig)
          if (afterCounts[side] === 0) return config // no-op
          return newConfig
        }
      })
    }

    // Type check is important here, otherwise you can forget to omit extra props.
    const cardConfig: Partial<Record<CardMediaType, Side>> = omit<
      Config,
      'audio'
    >(currentConfig, 'audio')
    const cardTypeMap = {
      ...invertBy(cardConfig),
      unused: difference(cardMediaTypes, Object.keys(currentConfig))
    } as Record<Side | 'unused', CardMediaType[]>

    return [
      { ...currentConfig, selectedMap: cardTypeMap },
      { toggle, reInit }
    ] as const
  }
)

function getCompleteMediaCounts(config: Config): Record<Side, number> {
  const counts = countBy(
    cardMediaTypes.filter(t => config.availableMediaTypeMap[t] === 'complete'),
    t => config[t]
  )
  return { front: 0, back: 0, ...counts }
}

function getInitialConfig({ courseId, course }: InitState): Config {
  return {
    word: 'front',
    ...(course.mediaTypes.image ? { image: 'front' } : {}),
    translation: 'back',
    availableMediaTypeMap: { ...course.mediaTypes, definition: 'complete' },
    courseId
  }
}

export const FlashCardPreferencesContainerReInit: React.FC<{
  courseId: string
  course: CourseEntity | null
}> = ({ courseId, course }) => {
  const [, { reInit }] = FlashCardPreferencesContainer.useContainer()
  const key = JSON.stringify(course && course.mediaTypes)
  useEffect(() => {
    if (course) reInit({ courseId, course })
  }, [key]) // eslint-disable-line react-hooks/exhaustive-deps
  return null
}
