import React, { useState, useEffect, useRef } from 'react'
import { CardEntity, Side, sides, CardMediaType } from '../entities'
import { Card, Toolbar, Icon } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import { MediaLayout } from './MediaLayout'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { FlashCardPreferencesContainer } from './FlashCardPreferencesContainer'
import { mediaTypeIcon } from './MediaTypeCaptions'
import { ToggleButtonProps } from '@material-ui/lab/ToggleButton'
import { fade } from '@material-ui/core/styles'
import { Howl } from 'howler'

interface FlashCardProps {
  card: CardEntity
  active: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    clickArea: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    spacer: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1)
    },
    toggleButton: {
      border: 0
    },
    toggleButtonSelected: {
      // to raise specificity
      '&$toggleButton': {
        color: theme.palette.primary.main,
        backgroundColor: fade(theme.palette.primary.main, 0.12),
        '&:hover': {
          backgroundColor: fade(theme.palette.primary.main, 0.15)
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0
        }
      }
    }
  })
)

const IconToggleButton: React.FC<{
  selected: boolean
  iconName: string
  onClick: ToggleButtonProps['onClick']
}> = ({ selected, iconName, onClick }) => {
  const classes = useStyles()
  return (
    <ToggleButton
      className="MuiToggleButton-sizeSmall"
      classes={{
        root: classes.toggleButton,
        selected: classes.toggleButtonSelected
      }}
      selected={selected}
      onClick={onClick}
    >
      <Icon>{iconName}</Icon>
    </ToggleButton>
  )
}

function getAudioUrl(courseId: string | null, word: string) {
  const courseIdFragment = courseId
    ? `&courseId=${encodeURIComponent(courseId)}`
    : ''
  return `/audio?word=${encodeURIComponent(word)}${courseIdFragment}`
}

const FlashCardBase: React.FC<FlashCardProps> = ({ card, active }) => {
  const classes = useStyles()
  const [selectedCard, setSelectedCard] = useState<Side | CardMediaType>(
    'front'
  )
  const [audioPlaying, setAudioPlaying] = useState(false)
  const [config] = FlashCardPreferencesContainer.useContainer()
  const cardMediaTypes = isSide(selectedCard)
    ? config.selectedMap[selectedCard]
    : [selectedCard]

  const soundRef = useRef<Howl | null>(null)
  const audioUrl = getAudioUrl(config.courseId, card.word)

  useEffect(() => {
    const sound = new Howl({
      src: audioUrl,
      format: ['mp3'],
      preload: false
    })
    sound.on('end', () => setAudioPlaying(false))
    soundRef.current = sound

    return () => {
      setAudioPlaying(false)
      sound.unload()
    }
  }, [audioUrl])

  const toggleAudio = (play: boolean) => {
    const sound = soundRef.current
    if (!sound) {
      console.error('Howler instance not created yet.')
      return
    }
    setAudioPlaying(play)
    if (play) {
      sound.load()
      sound.play()
    } else {
      sound.stop()
    }
  }

  useEffect(() => {
    if (!active) {
      toggleAudio(false)
    } else if (config.audio === selectedCard) {
      toggleAudio(true)
    }
  }, [active, selectedCard]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card classes={{ root: classes.root }} square elevation={0}>
      <div
        className={classes.clickArea}
        onClick={() =>
          setSelectedCard(selectedCard === 'front' ? 'back' : 'front')
        }
      >
        <MediaLayout
          card={card}
          courseId={config.courseId || ''}
          active={active}
          cardMediaTypes={cardMediaTypes}
          preloadMediaTypes={config.selectedMap.back}
          audioPlaying={audioPlaying}
          onClickAudioButton={() => toggleAudio(!audioPlaying)}
        />
      </div>
      <Toolbar variant="dense">
        <IconToggleButton
          key="front"
          selected={selectedCard === 'front'}
          iconName="flip_to_front"
          onClick={() => setSelectedCard('front')}
        />
        <IconToggleButton
          key="back"
          selected={selectedCard === 'back'}
          iconName="flip_to_back"
          onClick={() => setSelectedCard('back')}
        />
        {config.selectedMap.unused
          .filter(type => !!card[type])
          .map(mediaType => (
            <IconToggleButton
              key={mediaType}
              selected={selectedCard === mediaType}
              iconName={mediaTypeIcon[mediaType]}
              onClick={() => setSelectedCard(mediaType)}
            />
          ))}
        <div className={classes.spacer} />
        <IconToggleButton
          key="audio"
          selected={audioPlaying}
          iconName={mediaTypeIcon.audio}
          onClick={() => toggleAudio(!audioPlaying)}
        />
      </Toolbar>
    </Card>
  )
}

export const FlashCard = React.memo(FlashCardBase)

function isSide(
  sideOrMediaType: Side | CardMediaType
): sideOrMediaType is Side {
  return (sides as string[]).includes(sideOrMediaType)
}
