import { MediaType } from '../entities'

export const mediaTypeName = Object.freeze<Record<MediaType, string>>({
  word: '英語',
  translation: '日本語',
  definition: '英文定義',
  sentence: '英語例文',
  image: '画像',
  audio: '音声自動再生'
})

export const mediaTypeIcon = Object.freeze<Record<MediaType, string>>({
  word: 'spellcheck',
  translation: 'translate',
  definition: 'short_text',
  sentence: 'notes',
  image: 'image',
  audio: 'volume_up'
})
