import React, { useState, useEffect, CSSProperties, useRef } from 'react'
import { CardEntity, CardMediaType } from '../entities'
import { Typography, IconButton, Icon } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import ReactCardFlip from 'react-card-flip'
import { DefinitionLoader } from './DefinitionLoader'
import { mediaTypeIcon } from './MediaTypeCaptions'
import clsx from 'clsx'

interface MediaLayoutProps {
  active: boolean
  courseId: string
  card: CardEntity
  cardMediaTypes: CardMediaType[]
  preloadMediaTypes?: CardMediaType[]
  audioPlaying?: boolean
  onClickAudioButton?: () => void
}

const useStyles = makeStyles(theme =>
  createStyles({
    image: {
      objectFit: 'cover'
    },
    texts: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1
    },
    imageAndTextsContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      '& > $image': {
        flexGrow: 1.618
      },
      '& > $texts': {
        flexGrow: 1
      }
    },
    largeText: {
      fontWeight: 400,
      padding: theme.spacing(1, 2)
    },
    smallText: {
      padding: theme.spacing(4, 4)
    },
    audioButton: {
      display: 'block',
      margin: 'auto',

      '&$active': {
        margin: 'auto',
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,

        '&:hover': {
          background: fade(theme.palette.primary.main, 0.8)
        }
      }
    },
    active: {}
  })
)

type TextMediaType = Exclude<CardMediaType, 'image'>

const largeTexts = new Set<TextMediaType>(['word', 'translation'])

const Text: React.FC<{
  text: string
  cardMediaType: TextMediaType
}> = ({ text, cardMediaType }) => {
  const classes = useStyles()
  return largeTexts.has(cardMediaType) ? (
    <Typography
      classes={{ root: classes.largeText }}
      variant="h2"
      align="center"
    >
      {text}
    </Typography>
  ) : (
    <Typography classes={{ root: classes.smallText }} variant="h6" align="left">
      {text}
    </Typography>
  )
}

const MediaLayoutBody: React.FC<MediaLayoutProps> = ({
  active,
  courseId,
  card,
  cardMediaTypes,
  audioPlaying,
  onClickAudioButton
}) => {
  const classes = useStyles()

  const textTypes = cardMediaTypes.filter(
    (type): type is TextMediaType => type !== 'image'
  )
  const textsContainer = textTypes.length > 0 && (
    <div className={classes.texts}>
      {textTypes.map(type =>
        type === 'definition' && !card[type] ? (
          <DefinitionLoader
            key={type}
            active={active}
            word={card.word}
            courseId={courseId}
          >
            {text =>
              text === null ? (
                <Typography variant="body1" align="center">
                  (Error while fetching definition)
                </Typography>
              ) : (
                <Text text={text} cardMediaType={type} />
              )
            }
          </DefinitionLoader>
        ) : type === 'word' ? (
          <div key={type}>
            <Text key={type} text={card[type] || ''} cardMediaType={type} />
            <IconButton
              className={clsx(classes.audioButton, {
                [classes.active]: audioPlaying
              })}
              color="inherit"
              onClick={e => {
                e.stopPropagation() // card should not be flipped
                if (onClickAudioButton) onClickAudioButton()
              }}
            >
              <Icon fontSize="large">{mediaTypeIcon.audio}</Icon>
            </IconButton>
          </div>
        ) : (
          <Text key={type} text={card[type] || ''} cardMediaType={type} />
        )
      )}
    </div>
  )

  if (!cardMediaTypes.includes('image')) return textsContainer || <></>

  return (
    <div className={classes.imageAndTextsContainer}>
      <img
        className={classes.image}
        style={{ display: 'block', margin: '0 auto' }}
        src={card.image}
      />
      {textsContainer}
    </div>
  )
}

const style: CSSProperties = {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const cardStyle: CSSProperties = {
  display: 'flex'
}

export const MediaLayout: React.FC<MediaLayoutProps> = ({
  active,
  courseId,
  card,
  cardMediaTypes,
  preloadMediaTypes = [],
  audioPlaying,
  onClickAudioButton
}) => {
  // TODO: Follow preloadMediaTypes update
  const [state, setState] = useState<{
    flipped: boolean
    front: MediaLayoutProps['cardMediaTypes']
    back: MediaLayoutProps['cardMediaTypes']
  }>({ flipped: false, front: cardMediaTypes, back: preloadMediaTypes })
  const initialRef = useRef(true)
  const cardMediaTypesKey = JSON.stringify(cardMediaTypes)
  useEffect(() => {
    const initial = initialRef.current
    initialRef.current = false
    setState(s => {
      const flipped = initial ? false : !s.flipped
      return { ...s, flipped, [flipped ? 'back' : 'front']: cardMediaTypes }
    })
  }, [cardMediaTypesKey]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReactCardFlip
      infinite
      containerStyle={style}
      cardStyles={{ front: cardStyle, back: cardStyle }}
      flipSpeedFrontToBack={0.2}
      flipSpeedBackToFront={0.2}
      isFlipped={state.flipped}
    >
      <MediaLayoutBody
        key="front"
        active={active && !state.flipped}
        courseId={courseId}
        card={card}
        cardMediaTypes={state.front}
        audioPlaying={audioPlaying}
        onClickAudioButton={onClickAudioButton}
      />
      <MediaLayoutBody
        key="back"
        active={active && state.flipped}
        courseId={courseId}
        card={card}
        cardMediaTypes={state.back}
        audioPlaying={audioPlaying}
        onClickAudioButton={onClickAudioButton}
      />
    </ReactCardFlip>
  )
}
