/// <reference types="gapi.client.sheets" />

import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import FirebaseAuth from 'react-firebaseui/FirebaseAuth'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import Importer from './Importer'
import { useDocument } from 'react-firebase-hooks/firestore'

const UI_CONFIG = Object.freeze<firebaseui.auth.Config>({
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      scopes: ['email', 'https://www.googleapis.com/auth/drive.readonly']
    }
  ]
})

const Loading: React.FC = () => (
  <div style={{ textAlign: 'center' }}>
    <CircularProgress />
  </div>
)

const AdminSwitcher: React.FC = () => {
  const [user, loading, error] = useAuthState(firebase.auth())
  const adminEmailQuery =
    user && user.emailVerified
      ? firebase
          .firestore()
          .collection('adminEmails')
          .doc(user.email!)
      : null
  const [adminEmailDoc, adminLoading, adminError] = useDocument(adminEmailQuery)
  const [accessToken, setAccessToken] = useState<string | null>(null)

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!user) return
      const debugToken = sessionStorage.getItem(`UNSAFE_TOKEN_${user.uid}`)
      if (debugToken) setAccessToken(debugToken)
    }, [user])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!(user && accessToken)) return
      sessionStorage.setItem(`UNSAFE_TOKEN_${user.uid}`, accessToken)
    }, [user, accessToken])
  }

  if (error || adminError) {
    return <div>Error while authenticating.</div>
  }
  if (loading || (adminEmailQuery && adminLoading)) {
    return <Loading />
  }
  if (user && !user.emailVerified) {
    return <div>User email is not verified.</div>
  }
  if (adminEmailDoc && !adminEmailDoc.exists) {
    return <div>User does not have admin permission.</div>
  }

  if (user && accessToken) {
    return (
      <div>
        <div>{user.email}</div>
        <div>{user.displayName}</div>
        <Importer user={user} accessToken={accessToken} />
      </div>
    )
  }

  const uiConfig: firebaseui.auth.Config = {
    ...UI_CONFIG,
    callbacks: {
      signInSuccessWithAuthResult: (
        authResult: firebase.auth.UserCredential
      ) => {
        setAccessToken(
          (authResult.credential as firebase.auth.OAuthCredential).accessToken!
        )
        return true
      }
    }
  }
  return (
    <div>
      {user && 'Please re-authenticate to access google drive.'}
      <FirebaseAuth firebaseAuth={firebase.auth()} uiConfig={uiConfig} />
    </div>
  )
}

const AdminAppBar: React.FC = () => {
  const [user] = useAuthState(firebase.auth())
  return (
    <AppBar elevation={0}>
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap style={{ flexGrow: 1 }}>
          Clearly
        </Typography>
        {user && (
          <Button color="inherit" onClick={() => firebase.auth().signOut()}>
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}

let cssKicked = false

const Admin: React.FC = () => {
  useEffect(() => {
    if (!cssKicked) {
      import('firebaseui/dist/firebaseui.css')
      cssKicked = true
    }
  }, [])
  return (
    <div>
      <AdminAppBar />
      <main style={{ padding: 24, paddingTop: 80 }}>
        <AdminSwitcher />
      </main>
    </div>
  )
}

export default Admin
