import React from 'react'
import { FlashCardPreferencesContainer } from './FlashCardPreferencesContainer'
import {
  Toolbar,
  IconButton,
  Icon,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  styled,
  makeStyles,
  createStyles,
  Divider
} from '@material-ui/core'
import { cardMediaTypes, MediaType } from '../entities'
import { mediaTypeIcon, mediaTypeName } from './MediaTypeCaptions'
import { ResponsivePaper } from '../ui/ResponsivePaper'

const zeroWidthSpace = '\u200b'

const useColumnStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: theme.spacing(9)
    }
  })
)

const Spacing = styled('div')({
  flexGrow: 1
})

const Column: React.FC = ({ children }) => {
  const classes = useColumnStyles()
  return <div className={classes.root}>{children}</div>
}

const Row: React.FC<{
  type: MediaType
}> = ({ type }) => {
  const [config, { toggle }] = FlashCardPreferencesContainer.useContainer()
  return (
    <ListItem>
      <ListItemIcon>
        <Icon>{mediaTypeIcon[type]}</Icon>
      </ListItemIcon>
      <ListItemText primary={mediaTypeName[type]} />
      <Spacing />
      <Column>
        <Checkbox
          checked={config[type] === 'front'}
          onClick={() => toggle(type, 'front')}
        />
      </Column>
      <Column>
        <Checkbox
          checked={config[type] === 'back'}
          onClick={() => toggle(type, 'back')}
        />
      </Column>
    </ListItem>
  )
}

export const FlashCardPreferences: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const [config] = FlashCardPreferencesContainer.useContainer()
  const completeMediaTypes = cardMediaTypes.filter(
    type => config.availableMediaTypeMap[type] === 'complete'
  )
  const partialMediaTypes = cardMediaTypes.filter(
    type => config.availableMediaTypeMap[type] === 'partial'
  )

  const body = (
    <List>
      <ListItem>
        <ListItemText primary={zeroWidthSpace} />
        <Spacing />
        <Column>
          <Icon>flip_to_front</Icon>
          <Box marginLeft={0.5}>表面</Box>
        </Column>
        <Column>
          <Icon>flip_to_back</Icon>
          <Box marginLeft={0.5}>裏面</Box>
        </Column>
      </ListItem>
      {completeMediaTypes.map(type => (
        <Row key={type} type={type} />
      ))}
      <Row type="audio" />
      {partialMediaTypes.length > 0 && (
        <>
          <Divider component="li" />
          <ListItem>
            <ListItemText secondary="下記は一部のカードでのみ表示されます" />
          </ListItem>
        </>
      )}
      {partialMediaTypes.map(type => (
        <Row key={type} type={type} />
      ))}
    </List>
  )
  return (
    <>
      <Toolbar>
        <IconButton color="inherit" edge="start" onClick={onClose}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Box marginRight={2} />
        表示設定
      </Toolbar>
      <ResponsivePaper>{body}</ResponsivePaper>
    </>
  )
}
