import React from 'react'
import {
  MuiThemeProvider,
  createMuiTheme,
  AppBar,
  Toolbar,
  Typography,
  Box
} from '@material-ui/core'
import { teal, grey } from '@material-ui/core/colors'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import FlashCardListLoader from './flashcard/FlashCardListLoader'
import Admin from './admin/Admin'
import { Drawer } from './drawer/Drawer'
import { DrawerContainer } from './drawer/DrawerContainer'
import { DrawerButton } from './drawer/DrawerButton'
import { ResponsivePaper } from './ui/ResponsivePaper'
import { DrawerContent } from './drawer/DrawerContent'
import { CopyrightNotice } from './misc/CopyrightNotice'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Roboto', 'Noto Sans JP', 'sans-serif'].join(',')
  },
  palette: {
    primary: { main: teal.A700, contrastText: grey[50] },
    secondary: { main: teal.A700, contrastText: grey[50] }
  }
})

const DefaultToolbar = () => (
  <AppBar>
    <Toolbar>
      <DrawerButton />
      <Typography variant="h6" color="inherit">
        Clearly
      </Typography>
    </Toolbar>
  </AppBar>
)

const NoMatch: React.FC = () => (
  <>
    <DefaultToolbar />
    <Box marginTop={8}>No matching resource for specified URL.</Box>
  </>
)

const TopPage: React.FC = () => (
  <>
    <DefaultToolbar />
    <Box
      paddingTop={10}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="calc(100vh - var(--vh-offset, 0px))"
    >
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <ResponsivePaper>
          <DrawerContent />
        </ResponsivePaper>
      </Box>

      <Box display="flex" justifyContent="center">
        <CopyrightNotice />
      </Box>
    </Box>
  </>
)

const Routing: React.FC = () => (
  <Switch>
    <Route exact path="/courses/:id" component={FlashCardListLoader} />
    <Route exact path="/admin" component={Admin} />
    <Route exact path="/" component={TopPage} />
    <Route component={NoMatch} />
  </Switch>
)

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <DrawerContainer.Provider>
          <Routing />
          <Drawer />
        </DrawerContainer.Provider>
      </MuiThemeProvider>
    </BrowserRouter>
  )
}

export default App
