import React, { useState } from 'react'
import firebase from 'firebase/app'
import FlashCardList from './FlashCardList'
import { CourseEntity } from '../entities'
import { RouteComponentProps } from 'react-router'
import {
  FlashCardPreferencesContainer,
  FlashCardPreferencesContainerReInit
} from './FlashCardPreferencesContainer'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Icon,
  Box,
  Dialog,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { DrawerButton } from '../drawer/DrawerButton'
import { FlashCardPreferences } from './FlashCardPreferences'

import useDocumentData from '../compat/useDocumentData'

const FlashCardsToolbar: React.FC<{
  course: CourseEntity | null
  onOpenSettings: () => void
}> = ({ course, onOpenSettings }) => (
  <AppBar>
    <Toolbar>
      <DrawerButton />
      <Typography variant="h6" color="inherit">
        {course && course.name}
      </Typography>
      <Box flexGrow={1} />
      <IconButton color="inherit" edge="end" onClick={onOpenSettings}>
        <Icon>settings</Icon>
      </IconButton>
    </Toolbar>
  </AppBar>
)

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: 'calc(100vh - var(--vh-offset, 0px))',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start'
    },
    toolbarSpacer: theme.mixins.toolbar,
    main: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      // without this, height ignores toolbarSpacer.
      overflow: 'hidden'
    }
  })
)

const FlashCardListLoaderBody: React.FC<{
  course: CourseEntity | null
  loading: boolean
}> = ({ course, loading }) => {
  const [settingsOpen, setSettingsOpen] = useState(false)
  const classes = useStyles()
  return (
    <>
      <FlashCardsToolbar
        course={course}
        onOpenSettings={() => setSettingsOpen(true)}
      />
      {course ? (
        <>
          <div className={classes.container}>
            <div className={classes.toolbarSpacer} />
            <div className={classes.main}>
              <FlashCardList cards={course.cards} />
            </div>
          </div>
          <Dialog
            open={settingsOpen}
            onClose={() => setSettingsOpen(false)}
            fullScreen
          >
            <FlashCardPreferences onClose={() => setSettingsOpen(false)} />
          </Dialog>
        </>
      ) : (
        <>{!loading && <div>Could not retrieve cards.</div>}</>
      )}
    </>
  )
}

const FlashCardListLoader: React.FC<RouteComponentProps<{ id: string }>> = ({
  match
}) => {
  const courseId = match.params.id
  const [course = null, loading] = useDocumentData<CourseEntity>(
    firebase
      .firestore()
      .collection('courses')
      .doc(courseId)
  )
  return (
    <FlashCardPreferencesContainer.Provider key={courseId}>
      <FlashCardPreferencesContainerReInit
        courseId={courseId}
        course={course}
      />
      <FlashCardListLoaderBody course={course} loading={loading} />
    </FlashCardPreferencesContainer.Provider>
  )
}

export default FlashCardListLoader
